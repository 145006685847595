//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uiStore } from '@/store'

const UPDATE_INTERVAL = 30 * 1000

export default {
  data () {
    return {
      qrUrl: '',
      QRLoginActive: false,
    }
  },
  computed: {
    success () {
      const payload = uiStore.getters.currentModalPayload
      return payload && payload.success
    },
  },
  mounted () {
    this.updateQR()
    this.interval = window.setInterval(() => this.updateQR(), UPDATE_INTERVAL)
  },
  created () {
    this.QRLoginActive = window.FEATURES.auth_by_qr_code
  },
  beforeDestroy () {
    window.clearInterval(this.interval)
  },
  methods: {
    updateQR () {
      this.qrUrl = `/api/v4/auth/qr/login.png?r=${Math.random()}`
    },
    close () {
      uiStore.actions.hideModal()
    },
  },
}
